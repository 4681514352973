.menubar {
	@media screen and (max-width: $tablet_menu) {
		float: left;
		width: 100%;
		height: auto;
	}

	#menub {
		@media screen and (max-width: $tablet_menu) {
			width: 100%;
			border: none;
			float: left;
			height: auto;
		}

		#nav {
			@media screen and (max-width: $tablet_menu) {
				height: auto;
				width: 100%;
				border: none;
				display: none;
			}

			li {
				@media screen and (max-width: $tablet_menu) {
					width: 100%;
					background: #0e4c8f;
					border: none;
					height: auto;
				}

				&.customizebtn {
					@media screen and (max-width: $tablet_menu) {
						background: #c50000;
					}
				}

				.main {
					@media screen and (max-width: $tablet_menu) {
						position: static;
						opacity: 1;
						float: left;
						border-top: 1px solid white;
						display: none;
					}
				}

				a {
					@media screen and (max-width: $tablet_menu) {
						width: calc(100% - 40px);
						float: left;
						color: white;
					}
				}

				.submenu-caret-wrapper {
					display: inline-block;

					@media screen and (min-width: $tablet_menu + 1px) {
						display: none;
					}

					@media screen and (max-width: $tablet_menu) {
						padding: 17px 13px !important;
						float: right;
						display: block;
					}

					.caret {
						margin: 0 0 0 10px;
						color: white;

						@media screen and (max-width: $tablet_menu) {
							margin: 0;
							height: auto;
							line-height: inherit;
							padding: 0;
							display: inline-block;
						}
					}
				}
			}
		}
	}
}

.boxsearch {
	@media screen and (max-width: $tablet_menu) {
		width: 100%;
		background: #2656a2;
		height: auto;
		display: none;
	}

	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			color: white;
			opacity: 1; /* Firefox */
	}

	:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: white;
	}

	::-ms-input-placeholder { /* Microsoft Edge */
			color: white;
	}

	.textsearch {
		@media screen and (max-width: $tablet_menu) {
			width: 100%;
			border: 1px solid #ddd;
			color: white;
		}
	}

	.form-inner {
		@media screen and (max-width: $tablet_menu) {
			width: 100%;
			float: left;
			padding: 5px;
		}

		.catesearchbox {
			@media screen and (max-width: $tablet_menu) {
				width: 100%;
				float: left;
				margin: 0;
				padding: 0;
				height: auto;
			}

			#searchtype {
				@media screen and (max-width: $tablet_menu) {
					position: static;
					float: left;
					width: 100%;
				}

				li {
					@media screen and (max-width: $tablet_menu) {
						width: 100%;
					}
				}
			}

			.divcombo {
				@media screen and (max-width: $tablet_menu) {
					width: 100%;
					color: white;
				}

				&:after {
					@media screen and (max-width: $tablet_menu) {
						display: inline-block;
						width: 0;
						height: 0;
						border-right: 5px solid transparent;
						border-top: 5px solid white;
						border-left: 5px solid transparent;
						border-bottom: 0 dotted;
						vertical-align: middle;
						margin: 10px 2px;
						content: '';
						float: right;
					}
				}
			}
		}

		.countryesearchbox {
			@media screen and (max-width: $tablet_menu) {
				width: 100%;
				float: left;
				margin: 0;
				padding: 0;
				height: auto;
			}

			#list_country {
				@media screen and (max-width: $tablet_menu) {
					position: static;
					float: left;
					width: 100%;
				}

				li {
					@media screen and (max-width: $tablet_menu) {
						width: 100%;
					}
				}
			}

			.divcombo {
				@media screen and (max-width: $tablet_menu) {
					width: 100%;
					color: white;
				}

				&:after {
					@media screen and (max-width: $tablet_menu) {
						display: inline-block;
						width: 0;
						height: 0;
						border-right: 5px solid transparent;
						border-top: 5px solid white;
						border-left: 5px solid transparent;
						border-bottom: 0 dotted;
						vertical-align: middle;
						margin: 10px 2px;
						content: '';
						float: right;
					}
				}
			}
		}

		.btnsearch-wrapper {
			@media screen and (max-width: $tablet_menu) {
				width: 100%;
				text-align: center;
				float: left;
			}

			.btnsearch {
				@media screen and (max-width: $tablet_menu) {
					display: inline-block;
					background: #c50000;
					float: none;
					color: white;
					line-height: 26px;
					width: 75px;
				}
			}
		}
	}
}