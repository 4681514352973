.intropage {
	@media screen and (max-width: $mobile) {
		background-size: cover;
		width: 100%;
		min-width: 0;
		background:url(../images/bgintro-m.png) no-repeat left top;
	}

	#bodycontent {
		@media screen and (max-width: $mobile) {
			width: calc(100% - 20px);
		}

		#divintrotext {
			@media screen and (max-width: $mobile) {
				width: 100%;
			}

			img {
				@media screen and (max-width: $mobile) {
					max-width: 100%;
					height: auto !important;
				}
			}
		}

		.menufooter {
			a {
				@media screen and (max-width: $mobile) {
					width: 50%;
					float: left;
					margin-bottom: 7px;
					text-align: center;
					margin-left: 0;
					margin-right: 0;
				}
			}

			.separate-hline {
				@media screen and (max-width: $mobile) {
					display: none;
				}
			}
		}
	}
}