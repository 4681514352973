.modal-open {
	.modal-backdrop.in {
		opacity: 0;
	}
}

.table-striped>tbody>tr:nth-of-type(odd) {
	background-color: #f0f0f0;
}

.dropdown-menu {
	min-width: 175px;
	margin: 0;
}

.form-control {
	&:focus {
		box-shadow: none;
		border-color: $brand-1-color;
	}
}

.btn-default {
	background: $brand-1-color !important;
	border: none;
	color: white !important;
	text-shadow: none;
	outline: none !important;

	&:hover,
	&:active,
	&:focus {
		background: darken($brand-1-color, 5) !important;
		border: none;
		color: white;
		text-shadow: none;
	}
}

@media (min-width:1200px){
	.auto-clear .col-lg-1:nth-child(12n+1){clear:left;}
	.auto-clear .col-lg-2:nth-child(6n+1){clear:left;}
	.auto-clear .col-lg-3:nth-child(4n+1){clear:left;}
	.auto-clear .col-lg-4:nth-child(3n+1){clear:left;}
	.auto-clear .col-lg-6:nth-child(odd){clear:left;}
}
@media (min-width:992px) and (max-width:1199px){
	.auto-clear .col-md-1:nth-child(12n+1){clear:left;}
	.auto-clear .col-md-2:nth-child(6n+1){clear:left;}
	.auto-clear .col-md-3:nth-child(4n+1){clear:left;}
	.auto-clear .col-md-4:nth-child(3n+1){clear:left;}
	.auto-clear .col-md-6:nth-child(odd){clear:left;}
}
@media (min-width:768px) and (max-width:991px){
	.auto-clear .col-sm-1:nth-child(12n+1){clear:left;}
	.auto-clear .col-sm-2:nth-child(6n+1){clear:left;}
	.auto-clear .col-sm-3:nth-child(4n+1){clear:left;}
	.auto-clear .col-sm-4:nth-child(3n+1){clear:left;}
	.auto-clear .col-sm-6:nth-child(odd){clear:left;}
}
@media (max-width:767px){
	.auto-clear .col-xs-1:nth-child(12n+1){clear:left;}
	.auto-clear .col-xs-2:nth-child(6n+1){clear:left;}
	.auto-clear .col-xs-3:nth-child(4n+1){clear:left;}
	.auto-clear .col-xs-4:nth-child(3n+1){clear:left;}
	.auto-clear .col-xs-6:nth-child(odd){clear:left;}
}

.tabs-left, .tabs-right {
	border-bottom: none;
	padding-top: 2px;
}

.tabs-left>li, .tabs-right>li {
	float: none;
	margin-bottom: 2px;
}
.tabs-left>li {
	margin-right: -1px;
}
.tabs-right>li {
	margin-left: -1px;
}
.tabs-left>li.active>a,
.tabs-left>li.active>a:hover,
.tabs-left>li.active>a:focus {
	background: transparent;
	border: none;
	border-right-color: transparent;
}

.tabs-right>li.active>a,
.tabs-right>li.active>a:hover,
.tabs-right>li.active>a:focus {
	border-left-color: transparent;
}
.tabs-left>li>a {
	padding: 0;
	margin-right: 0;
	display:block;
	border: none;
}
.tabs-right>li>a {
	border-radius: 0 4px 4px 0;
	margin-right: 0;
}
.vertical-text {
	margin-top:50px;
	border: none;
	position: relative;
}
.vertical-text>li {
	height: 20px;
	width: 120px;
	margin-bottom: 100px;
}
.vertical-text>li>a {
	border-bottom: 1px solid #ddd;
	border-right-color: transparent;
	text-align: center;
	border-radius: 4px 4px 0px 0px;
}
.vertical-text>li.active>a,
.vertical-text>li.active>a:hover,
.vertical-text>li.active>a:focus {
	border-bottom-color: transparent;
	border-right-color: #ddd;
	border-left-color: #ddd;
}
.vertical-text.tabs-left {
	left: -50px;
}
.vertical-text.tabs-right {
	right: -50px;
}
.vertical-text.tabs-right>li {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
.vertical-text.tabs-left>li {
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg);
}