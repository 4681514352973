.cell_cat_gallery {
	@media screen and (max-width: $mobile) {
		width: 100%;
	}

	@media screen and (min-width: $mobile + 1px) and (max-width: $tablet) {
		width: 50%;
	}

	@media screen and (max-width: $tablet) {
		float: left;
		margin: 0 0 15px 0;
		padding: 4px;
	}

	a {
		@media screen and (max-width: $tablet) {
			width: 100%;
			height: auto !important;
			margin: 0;
			padding: 0;
			background: none;
		}

		img {
			@media screen and (max-width: $tablet) {
				width: 100%;
				height: auto !important;
			}
		}
	}
}

.cell_gallery_home {
	@media screen and (max-width: $mobile) {
		width: 50%;
	}

	@media screen and (min-width: $mobile + 1px) and (max-width: $tablet) {
		width: 25%;
	}

	@media screen and (max-width: $tablet) {
		margin: 0 0 10px 0;
		height: auto;
		padding: 3px;
	}

	img {
		width: 100%;
	}
}