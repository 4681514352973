.videocell {
	@media screen and (max-width: $mobile) {
		width: 100%;
	}

	@media screen and (min-width: $mobile + 1px) and (max-width: $tablet) {
		width: 50%;
	}

	@media screen and (max-width: $tablet) {
		text-align: center;
		margin: 0 0 15px 0;
		padding: 0;
	}
}

.videoshow {
	@media screen and (max-width: $tablet) {
		width: 100%;
	}

	.videowrapper {
		float: none;
		clear: both;
		width: 100%;
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 25px;
		height: 0;
	}

	.videowrapper iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.itemnameblue {
		font-size: 17px;
		text-align: center;
		line-height: 30px;
	}
}

.videos-wrapper {
	.c15 {
		display: none;
	}

	.itemnameblue a {
		margin-top: 5px;
		float: left;
		padding: 0 10px;
		text-align: center;
		width: 100%;
	}
}
