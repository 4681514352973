body {
	float: left;
	width: 100%;
}

#bodypage {
	@media screen and (max-width: $tablet) {
		float: left;
		width: 100%;
	}

	img {
		@media screen and (max-width: $tablet) {
			max-width: 100%;
			height: auto !important;
		}
	}

	.hothomebox {
		@media screen and (max-width: $tablet) {
			max-width: 100%;
		}

		.imagemarginright5px {
			@media screen and (max-width: $tablet) {
				display: none;
			}
		}
	}

	.boxfeedbackhome {
		@media screen and (max-width: $tablet) {
			max-width: 100%;
			float: left;
			width: 100%;
		}

		.title {
			padding: 0 5px;

			@media screen and (max-width: $tablet) {
				background-size: cover;
				padding: 5px;
			}
		}
	}

	.cell_tourhome1 {
		@media screen and (max-width: $tablet) {
			float: left;
			width: 100%;
			margin: 0 0 10px 0;
			padding: 0;
		}

		.image {
			@media screen and (max-width: $tablet) {
				height: auto !important;
				float: left;
				width: 100%;
				margin: 0;
				background: none;
				padding: 0;
			}

			a {
				@media screen and (max-width: $tablet) {
					width: 100%;
					height: auto !important;
					background: none;
					margin: 0;
					padding: 0;
				}

				img {
					@media screen and (max-width: $tablet) {
						width: 100%;
						height: auto !important;
					}
				}
			}
		}

		.itemname {
			@media screen and (max-width: $tablet) {
				font-size: 15px;
				line-height: 30px;
			}
		}
	}

	.pathpage {
		@media screen and (max-width: $tablet) {
			height: auto;
		}
	}

	.hothomename {
		@media screen and (max-width: $tablet) {
			float: left;
			margin: 15px 5px 10px 5px;
			width: 100%;
		}
	}

	.advhome2 {
		@media screen and (max-width: $tablet) {
			width: 100%;
		}
	}
}

// Lên đầu trang
#back-top {
	bottom: 25px;
	right: 15px;
	position: fixed;
	z-index: 999;

	a {
		color: #BBB;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 12px;
		line-height: 26px;
		display: block;
		text-align: center;

		&:hover {
			color: black;

			span {
				background-color: darken($brand-1-color, 5) ;
			}
		}
	}

	span {
		background: darken($brand-1-color, 10)  url("../images/up-arrow.png") no-repeat scroll center center;
		border-radius: 5px;
		width: 45px;
		height: 45px;
		display: block;
		margin-bottom: 7px;
	}
}

.imagemarginright5px {
	margin-right: 10px;
}

.textboxfaqright,
.textareafaqright {
	margin: 0 0 10px 10px;

	@media screen and (max-width: $tablet) {
		width: calc(100% - 20px) !important;
	}
}