.boxmenufooter {
	@media screen and (max-width: $tablet) {
		width: 100%;
		float: left;
		min-width: 0;
		margin-bottom: 10px;
	}

	.inner {
		@media screen and (max-width: $tablet) {
			width: auto !important;
		}
	}

	.colfooter {
		@media screen and (max-width: $tablet) {
			width: 100%;
		}

		ul {
			li {
				@media screen and (max-width: $tablet) {
					text-align: center;
				}

				a {
					@media screen and (max-width: $tablet) {
						display: inline-block;
					}
				}
			}
		}
	}
}

#footer {
	height: auto;
	width: 100%;
	min-width: 0;
	padding: 10px;

	.inner {
		@media screen and (max-width: $tablet) {
			width: auto !important;
		}

		.company-info {
			@media screen and (max-width: $tablet) {
				width: 100% !important;
			}
		}

		.addthis-wrapper {
			@media screen and (max-width: $tablet) {
				width: 100% !important;
				width: 100%;
				float: left;
				margin-top: 10px;
			}
		}
	}
}