.banner {
	@media screen and (max-width: $tablet_menu) {
		min-width: 0;
		height: auto;
		float: left;
		width: 100%;
		padding: 0;
	}

	#banner {
		@media screen and (max-width: $tablet_menu) {
			width: 100%;
			float: left;
			height: auto;
		}

		#menutoppage {
			@media screen and (max-width: $tablet_menu) {
				position: static;
				float: left;
				width: 100%;
				height: auto;
				padding: 10px;
			}

			#langbarheader {
				@media screen and (max-width: $tablet_menu) {
					position: absolute;
					bottom: 54px;
					right: 7px;
				}
			}

			.hotline {
				@media screen and (max-width: $tablet_menu) {
					text-align: center;
					height: auto;
					width: calc(100% - 58px);
					float: left;
					line-height: 34px;
				}
			}

			.glyphicon-search {
				display: none;

				@media screen and (max-width: $tablet_menu) {
					color: #0d4b8e;
					font-size: 15px;
					float: left;
					display: block;
					line-height: 34px;
					padding-right: 10px;
				}
			}

			.navbar-toggle {
				float: left;
				margin: 0;
				padding-left: 0;

				@media screen and (max-width: $tablet_menu) {
					display: block !important;
				}

				@media screen and (min-width: $tablet_menu + 1px) {
					display: none;
				}

				.icon-bar {
					background-color: $brand-1-color;
					width: 20px;
					height: 2px;
				}
			}

			#like-box {
				@media screen and (max-width: $tablet_menu) {
					display: none;
				}
			}
		}
	}

	#banner_d {
		@media screen and (max-width: $tablet_menu) {
			display: none;
		}
	}

	#banner_m {
		@media screen and (min-width: $tablet_menu + 1px) {
			display: none;
		}

		img {
			width: 100%;
			max-width: 200px;
			margin-left: 10px;
		}
	}
}

#google_language_translator {
	position: absolute;
	bottom: 0;
	right: 0;

	@media screen and (max-width: $tablet) {
		display: none;
	}
}