#custombookform {
	.hasDatepicker {
		@media screen and (max-width: $tablet) {
			border: 1px solid #CCC;
		}
	}
}

.yourinfo-contact-tbl {
	@media screen and (max-width: $tablet) {
		width: 100%;
		margin-bottom: 10px;
	}

	tr {
		td:first-child {
			@media screen and (max-width: $tablet) {
				width: 100px;
				display: block;
				float: left;
			}
		}

		td:nth-child(2) {
			@media screen and (max-width: $tablet) {
				width: calc(100% - 100px);
				display: block;
				float: left;
			}
		}

		input,
		textarea {
			@media screen and (max-width: $tablet) {
				border: 1px solid #CCC;
				width: 100% !important;
				margin-bottom: 10px;
			}
		}

		#sercurity {
			@media screen and (max-width: $tablet) {
				margin: 0 0 10px 0;
			}
		}
	}
}

.map-chooser {
	tbody {
		@media screen and (max-width: $tablet) {
			float: left;
			width: 100%;
			display: block;
		}

		tr {
			@media screen and (max-width: $tablet) {
				float: left;
				width: 100%;
			}

			td {
				@media screen and (max-width: $tablet) {
					display: block;
					float: left;
					width: 100%;
				}
			}
		}

		.combocustomizetour {
			@media screen and (max-width: $tablet) {
				border-right: 1px solid #979797;
			}
		}
	}
}

.tourtabcontent {
	table {
		@media screen and (max-width: $tablet) {
			width: 100%;
		}
	}
}

.tour-tabs {
	@media screen and (max-width: $tablet) {
		padding: 0 10px;
	}

	.owl-nav {
		position: absolute;
		left: 0;
		top: -5px;
		width: 100%;
		display: block !important;

		@media screen and (min-width: $tablet + 1px) {
			display: none !important;
		}

		@media screen and (max-width: $tablet) {
			display: block !important;
		}

		.owl-prev,
		.owl-next {
			background: transparent;
			position: absolute;
			background: transparent;
			margin: 0;
			padding: 0;

			i {
				font-size: 11px;
				color: #3c3333;
			}
		}

		.owl-prev {
			left: 0;
		}

		.owl-next {
			right: 0;
		}
	}
}
