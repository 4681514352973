.slideshow {
	padding: 0;
	box-shadow: none;
	border: none;
	width: 997px;
	display: none;

	@media screen and (max-width: $tablet) {
		width: 100%;
		height: auto !important;
		float: left;
		width: 100%;
		padding: 0;
	}

	.number_slideshow {
		@media screen and (max-width: $tablet) {
			width: 100% !important;
			height: auto !important;
		}

		ul {
			@media screen and (max-width: $tablet) {
				width: 100% !important;
				height: auto !important;
				padding: 0 !important;
			}

			li {
				@media screen and (max-width: $tablet) {
					width: 100% !important;
					height: auto !important;
				}

				img {
					@media screen and (max-width: $tablet) {
						width: 100% !important;
						height: auto !important;
					}
				}
			}
		}

		.number_slideshow_nav {
			@media screen and (max-width: $tablet) {
				display: none;
			}
		}
	}
}