$brand-1-color: #0d4b8e;
$brand-2-color: #be740d;
$divider-color: #ddd;
$text-color: #333;
$mobile: 500px;
$tablet: 1100px;
$tablet_menu: 1020px;

@import 'override_bootstrap';
@import 'global';
@import 'intro';
@import 'nav';
@import 'header';
@import 'slideshow';
@import 'footer';
@import 'home';
@import 'post';
@import 'gallery';
@import 'contact';
@import 'video';
@import 'tour';
@import 'custombookform';