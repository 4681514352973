.c-post-wrapper {
	@media screen and (min-width: $tablet) {
		width: calc(100% + 30px);
		margin-left: -15px;
	}

	@media screen and (max-width: $tablet) {
		margin: 0 -5px;
	}

	.c-post {
		@media screen and (max-width: $tablet) {
			margin-bottom: 15px;
			padding: 0 5px;
		}

		.image {
			width: 100%;

			a {
				width: 100%;
				float: left;

				img {
					width: 100%;
				}
			}
		}

		.itemname {
			float: left;
			width: 100%;
		}

		.intro {
			float: left;
			width: 100%;
		}
	}
}

.cellotherarticle {
	@media screen and (max-width: $tablet) {
		width: 100%;
		float: left;
		margin-bottom: 10px;
	}
}