#leftcol {
	@media screen and (max-width: $tablet) {
		width: 100%;
	}

	#homecol1 {
		@media screen and (max-width: $tablet) {
			width: 100%;
		}

		.item {
			@media screen and (min-width: 400px) and (max-width: $tablet) {
				width: 30%;
				float: left;
				margin-right: 10px;
			}

			> a {
				@media screen and (max-width: $tablet) {
					width: 100%;
					height: auto !important;
					margin: 0;
					padding: 0;
					background: none;
				}

				img {
					max-width: 100%;
					width: 100%;

					@media screen and (max-width: $tablet) {
						width: 100%;
						height: auto !important;
					}
				}
			}
		}
	}

	.newslist {

		@media screen and (max-width: $mobile) {
			width: 100%;
			border-bottom: none;
		}

		@media screen and (min-width: $mobile + 1px) and (max-width: 900px) {
			width: 50%;
			float: left;
			padding: 4px;
			border-bottom: none;

			&:nth-child(2n+1) {
				clear: left;
			}
		}

		@media screen and (min-width: 901px) and (max-width: $tablet) {
			width: 33%;
			float: left;
			padding: 4px;

			&:nth-child(3n+1) {
				clear: left;
			}
		}

		> a {
			background: none;
			margin: 0 10px 0 0;
			padding: 0;
			height: auto;

			@media screen and (max-width: $tablet) {
				width: 100%;
				height: auto !important;
			}

			img {
				width: 100%;
				height: auto !important;
			}
		}
	}
}

#viewmore-hotel-tablet {
	text-align: center;
	float: left;
	width: 100%;
	margin: 10px 0;

	@media screen and (min-width: $tablet + 1px) {
		display: none;
	}

	button {
		border: none;
		display: inline-block;
	}
}

#homecol2 {
	@media screen and (max-width: $tablet) {
		width: 100%;
		margin: 0;
		display: none;
	}

	.item {
		@media screen and (max-width: $tablet) {
			padding-right: 10px;
		}
	}
}

#homecol3 {
	@media screen and (max-width: $tablet) {
		width: 100%;
		margin: 0;
		display: none;
	}

	.boxborderbdbec2 {
		iframe {
			@media screen and (max-width: $tablet) {
				width: 100%;
			}
		}
	}

	.boxmaphome {
		@media screen and (min-width: $mobile) and (max-width: $tablet) {
			width: 50%;
			float: left;
		}
	}

	.boxvideohome {
		@media screen and (min-width: $mobile) and (max-width: $tablet) {
			width: 50%;
			float: left;
		}

		a {
			@media screen and (max-width: $tablet) {
				float: left;
				width: 100%;
			}

			img {
				@media screen and (max-width: $tablet) {
					width: 100%;
				}
			}
		}
	}
}

.adv-home {
	img {
		@media screen and (max-width: $tablet) {
			max-width: 100%;
		}
	}
}

.post-slider {
	.owl-nav {
		position: absolute;
		top: 50px;
		width: 100%;

		@media screen and (max-width: $tablet) {
			position: absolute;
			top: 55px;
			width: 100%;
		}

		@media screen and (max-width: 500px) {
			top: 35px;
		}

		@media screen and (max-width: 350px) {
			position: absolute;
			top: 70px;
			width: 100%;
		}

		.glyphicon {
			color: white;
		}

		.owl-prev {
			position: absolute;
			left: 10px;
			background: transparent;

			@media screen and (max-width: $tablet) {
				left: 20px;
				padding: 0;
				margin: 0;
				height: 32px;
			}

			&:hover {
				background: transparent;
			}

			.owl-left {
				background: url('../images/owl-left.png');
				background-repeat: no-repeat;
				width: 26px;
				height: 28px;
				background-size: 25px auto;
				padding: 0;
				margin: 3px;
			}
		}

		.owl-next {
			position: absolute;
			right: 10px;
			background: transparent;

			@media screen and (max-width: $tablet) {
				right: 20px;
				padding: 0;
				margin: 0;
				height: 32px;
			}

			&:hover {
				background: transparent;
			}

			.owl-right {
				background: url('../images/owl-right.png');
				background-repeat: no-repeat;
				width: 26px;
				height: 28px;
				background-size: 26px auto;
				margin: 3px;
				padding: 0;
			}
		}
	}
}

.post-slider-tablet {
	@media screen and (min-width: $tablet + 1px) {
		display: block !important;
	}

	.owl-nav {
		position: absolute;
		top: 50px;
		width: 100%;

		@media screen and (max-width: $tablet) {
			position: absolute;
			top: 55px;
			width: 100%;
			display: block !important;
		}

		@media screen and (max-width: 500px) {
			top: 35px;
		}

		@media screen and (max-width: 350px) {
			position: absolute;
			top: 70px;
			width: 100%;
		}

		.glyphicon {
			color: white;
		}

		.owl-prev {
			position: absolute;
			left: 10px;
			background: transparent;

			@media screen and (max-width: $tablet) {
				left: 10px;
				padding: 0;
				margin: 0;
				height: 32px;
			}

			&:hover {
				background: transparent;
			}

			.owl-left {
				background: url('../images/owl-left.png');
				background-repeat: no-repeat;
				width: 26px;
				height: 28px;
				background-size: 25px auto;
				padding: 0;
				margin: 3px;
			}
		}

		.owl-next {
			position: absolute;
			right: 10px;
			background: transparent;

			@media screen and (max-width: $tablet) {
				right: 40px;
				padding: 0;
				margin: 0;
				height: 32px;
			}

			&:hover {
				background: transparent;
			}

			.owl-right {
				background: url('../images/owl-right.png');
				background-repeat: no-repeat;
				width: 26px;
				height: 28px;
				background-size: 26px auto;
				margin: 3px;
				padding: 0;
			}
		}
	}
}